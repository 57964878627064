import { FC, SVGAttributes } from 'react';

export const Picture: FC<SVGAttributes<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M3.16663 10.6667L4.99742 8.3378C5.51656 7.6774 6.50965 7.65581 7.05703 8.29301L8.66663 10.1667M7.27663 8.54867C7.96809 7.66914 8.93149 6.42304 8.99423 6.34197C8.99643 6.33907 8.99856 6.33633 9.00083 6.33345C9.52096 5.67733 10.5108 5.65721 11.057 6.29299L12.6666 8.16667M4.49996 12.8333H11.5C12.2364 12.8333 12.8333 12.2364 12.8333 11.5V4.50001C12.8333 3.76363 12.2364 3.16667 11.5 3.16667H4.49996C3.76358 3.16667 3.16663 3.76363 3.16663 4.50001V11.5C3.16663 12.2364 3.76358 12.8333 4.49996 12.8333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
