import { FC, SVGAttributes } from 'react';

export const Trash: FC<SVGAttributes<SVGSVGElement>> = props => (
  <svg width="15" height="17" viewBox="0 0 15 17" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4048 3.62905C12.9397 3.67557 13.3357 4.14692 13.2892 4.68184L12.5168 13.5639C12.5168 13.5639 12.5168 13.5639 12.5168 13.564C12.3906 15.0154 11.1755 16.1293 9.71875 16.1293H5.28125C3.82441 16.1293 2.60938 15.0154 2.48317 13.564L3.45174 13.4797L2.48317 13.564L1.71082 4.68184C1.6643 4.14691 2.06024 3.67556 2.59516 3.62905C3.13009 3.58253 3.60144 3.97847 3.64795 4.51339L4.4203 13.3955C4.45913 13.8421 4.83297 14.1848 5.28125 14.1848H9.71875C10.167 14.1848 10.5408 13.8421 10.5796 13.3956L10.5796 13.3955L11.352 4.51339C11.3985 3.97846 11.8699 3.58253 12.4048 3.62905Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.27045 2.81523C6.79315 2.81523 6.40626 3.20214 6.40626 3.67943V4.36809C6.40626 4.90503 5.97098 5.34031 5.43403 5.34031C4.89709 5.34031 4.46181 4.90503 4.46181 4.36809V3.67943C4.46181 2.12827 5.71925 0.870789 7.27045 0.870789H7.72956C9.28077 0.870789 10.5382 2.12827 10.5382 3.67943V4.36809C10.5382 4.90503 10.1029 5.34031 9.56598 5.34031C9.02903 5.34031 8.59376 4.90503 8.59376 4.36809V3.67943C8.59376 3.20214 8.20686 2.81523 7.72956 2.81523H7.27045Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.100342 4.59765C0.100342 4.06071 0.535621 3.62543 1.07256 3.62543H13.9275C14.4644 3.62543 14.8997 4.06071 14.8997 4.59765C14.8997 5.13459 14.4644 5.56987 13.9275 5.56987H1.07256C0.535621 5.56987 0.100342 5.13459 0.100342 4.59765Z"
      fill="currentColor"
    />
  </svg>
);
