import { FC, SVGAttributes } from 'react';

export const Wordmark: FC<SVGAttributes<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg height="24" viewBox="0 0 198 20" fill="none" {...props}>
      <path
        d="M54.088 15.561C53.556 15.561 53.15 15.421 52.87 15.141C52.59 14.861 52.45 14.462 52.45 13.944V6.48901C52.45 5.97101 52.583 5.57901 52.849 5.31301C53.129 5.03301 53.514 4.89301 54.004 4.89301C54.508 4.89301 54.893 5.03301 55.159 5.31301C55.425 5.57901 55.558 5.97101 55.558 6.48901V7.58101H55.348C55.516 6.74101 55.887 6.09001 56.461 5.62801C57.035 5.16601 57.777 4.90701 58.687 4.85101C59.037 4.82301 59.303 4.91401 59.485 5.12401C59.681 5.32001 59.786 5.64901 59.8 6.11101C59.828 6.54501 59.737 6.88801 59.527 7.14001C59.331 7.39201 58.995 7.54601 58.519 7.60201L58.036 7.64401C57.238 7.71401 56.643 7.94501 56.251 8.33701C55.873 8.72901 55.684 9.29601 55.684 10.038V13.944C55.684 14.462 55.544 14.861 55.264 15.141C54.998 15.421 54.606 15.561 54.088 15.561Z"
        fill="#753713"
      />
      <path
        d="M46.28 15.6031C45.062 15.6031 44.012 15.3861 43.13 14.9521C42.262 14.5041 41.59 13.8811 41.114 13.0831C40.652 12.2711 40.421 11.3191 40.421 10.2271C40.421 9.16307 40.645 8.23207 41.093 7.43407C41.541 6.62207 42.164 5.99207 42.962 5.54407C43.774 5.08207 44.691 4.85107 45.713 4.85107C46.455 4.85107 47.127 4.97707 47.729 5.22907C48.331 5.46707 48.849 5.81707 49.283 6.27907C49.717 6.72707 50.046 7.28007 50.27 7.93807C50.494 8.58207 50.606 9.30307 50.606 10.1011C50.606 10.3671 50.522 10.5701 50.354 10.7101C50.186 10.8361 49.941 10.8991 49.619 10.8991H43.025V9.26107H48.317L47.981 9.55507C47.981 8.98107 47.897 8.50507 47.729 8.12707C47.561 7.73507 47.316 7.44107 46.994 7.24507C46.686 7.04907 46.301 6.95107 45.839 6.95107C45.321 6.95107 44.88 7.07007 44.516 7.30807C44.152 7.54607 43.872 7.88907 43.676 8.33707C43.48 8.78507 43.382 9.32407 43.382 9.95407V10.1221C43.382 11.1861 43.627 11.9701 44.117 12.4741C44.621 12.9781 45.363 13.2301 46.343 13.2301C46.679 13.2301 47.064 13.1881 47.498 13.1041C47.932 13.0201 48.338 12.8871 48.716 12.7051C49.038 12.5511 49.325 12.5021 49.577 12.5581C49.829 12.6001 50.025 12.7121 50.165 12.8941C50.305 13.0761 50.382 13.2861 50.396 13.5241C50.424 13.7621 50.375 14.0001 50.249 14.2381C50.123 14.4621 49.913 14.6511 49.619 14.8051C49.143 15.0711 48.604 15.2671 48.002 15.3931C47.414 15.5331 46.84 15.6031 46.28 15.6031Z"
        fill="#753713"
      />
      <path
        d="M37.598 15.603C36.632 15.603 35.82 15.442 35.162 15.12C34.518 14.798 34.035 14.329 33.713 13.713C33.405 13.097 33.251 12.32 33.251 11.382V7.43402H29.786C29.394 7.43402 31.669 7.32902 31.445 7.11902C31.235 6.90902 31.13 6.62202 31.13 6.25802C31.13 5.86602 31.235 5.57202 31.445 5.37602C31.669 5.16602 29.894 5.06102 30.286 5.06102H33.251V3.46502C33.251 2.93302 33.391 2.53402 33.671 2.26802C33.951 1.98802 34.343 1.84802 34.847 1.84802C35.365 1.84802 35.757 1.98802 36.023 2.26802C36.289 2.53402 36.422 2.93302 36.422 3.46502V5.06102H38.417C38.809 5.06102 39.11 5.16602 39.32 5.37602C39.53 5.57202 39.635 5.86602 39.635 6.25802C39.635 6.62202 39.53 6.90902 39.32 7.11902C39.11 7.32902 38.809 7.43402 38.417 7.43402H36.422V11.256C36.422 11.844 36.555 12.285 36.821 12.579C37.101 12.873 37.542 13.02 38.144 13.02C38.354 13.02 38.55 12.999 38.732 12.957C38.914 12.915 39.082 12.887 39.236 12.873C39.432 12.859 39.593 12.922 39.719 13.062C39.845 13.188 39.908 13.475 39.908 13.923C39.908 14.259 39.852 14.553 39.74 14.805C39.628 15.057 39.432 15.239 39.152 15.351C38.97 15.421 38.718 15.477 38.396 15.519C38.088 15.575 37.822 15.603 37.598 15.603Z"
        fill="#753713"
      />
      <path
        d="M29.867 15.603C28.901 15.603 28.089 15.442 27.431 15.12C26.787 14.798 26.304 14.329 25.982 13.713C25.674 13.097 25.52 12.32 25.52 11.382V7.43402H24.638C24.246 7.43402 23.938 7.32902 23.714 7.11902C23.504 6.90902 23.399 6.62202 23.399 6.25802C23.399 5.86602 23.504 5.57202 23.714 5.37602C23.938 5.16602 24.246 5.06102 24.638 5.06102H25.52V3.46502C25.52 2.93302 25.66 2.53402 25.94 2.26802C26.22 1.98802 26.612 1.84802 27.116 1.84802C27.634 1.84802 28.026 1.98802 28.292 2.26802C28.558 2.53402 28.691 2.93302 28.691 3.46502V5.06102H30.686C31.078 5.06102 31.379 5.16602 31.589 5.37602C31.799 5.57202 31.904 5.86602 31.904 6.25802C31.904 6.62202 31.799 6.90902 31.589 7.11902C31.379 7.32902 31.078 7.43402 30.686 7.43402H28.691V11.256C28.691 11.844 28.824 12.285 29.09 12.579C29.37 12.873 29.811 13.02 30.413 13.02C30.623 13.02 30.819 12.999 31.001 12.957C31.183 12.915 31.351 12.887 31.505 12.873C31.701 12.859 31.862 12.922 31.988 13.062C32.114 13.188 32.177 13.475 32.177 13.923C32.177 14.259 32.121 14.553 32.009 14.805C31.897 15.057 31.701 15.239 31.421 15.351C31.239 15.421 30.987 15.477 30.665 15.519C30.357 15.575 30.091 15.603 29.867 15.603Z"
        fill="#753713"
      />
      <path
        d="M20.783 15.54C20.265 15.54 19.866 15.393 19.586 15.099C19.32 14.791 19.187 14.364 19.187 13.818V6.636C19.187 6.076 19.32 5.649 19.586 5.355C19.866 5.047 20.265 4.893 20.783 4.893C21.287 4.893 21.672 5.047 21.938 5.355C22.218 5.649 22.358 6.076 22.358 6.636V13.818C22.358 14.364 22.225 14.791 21.959 15.099C21.693 15.393 21.301 15.54 20.783 15.54ZM20.783 3.171C20.195 3.171 19.74 3.038 19.418 2.772C19.11 2.492 18.956 2.1 18.956 1.596C18.956 1.078 19.11 0.686 19.418 0.42C19.74 0.14 20.195 0 20.783 0C21.371 0 21.819 0.14 22.127 0.42C22.435 0.686 22.589 1.078 22.589 1.596C22.589 2.1 22.435 2.492 22.127 2.772C21.819 3.038 21.371 3.171 20.783 3.171Z"
        fill="#753713"
      />
      <path
        d="M12.17 15.561C11.638 15.561 11.232 15.421 10.952 15.141C10.672 14.861 10.532 14.462 10.532 13.944V6.48901C10.532 5.97101 10.665 5.57901 10.931 5.31301C11.211 5.03301 11.596 4.89301 12.086 4.89301C12.59 4.89301 12.975 5.03301 13.241 5.31301C13.507 5.57901 13.64 5.97101 13.64 6.48901V7.58101H13.43C13.598 6.74101 13.969 6.09001 14.543 5.62801C15.117 5.16601 15.859 4.90701 16.769 4.85101C17.119 4.82301 17.385 4.91401 17.567 5.12401C17.763 5.32001 17.868 5.64901 17.882 6.11101C17.91 6.54501 17.819 6.88801 17.609 7.14001C17.413 7.39201 17.077 7.54601 16.601 7.60201L16.118 7.64401C15.32 7.71401 14.725 7.94501 14.333 8.33701C13.955 8.72901 13.766 9.29601 13.766 10.038V13.944C13.766 14.462 13.626 14.861 13.346 15.141C13.08 15.421 12.688 15.561 12.17 15.561Z"
        fill="#753713"
      />
      <path
        d="M5.523 15.6031C4.403 15.6031 3.423 15.3861 2.583 14.9521C1.757 14.5041 1.12 13.8741 0.672 13.0621C0.224 12.2501 0 11.2911 0 10.1851C0 9.35907 0.126 8.61707 0.378 7.95907C0.63 7.30107 0.994 6.74107 1.47 6.27907C1.96 5.81707 2.548 5.46707 3.234 5.22907C3.92 4.97707 4.683 4.85107 5.523 4.85107C5.957 4.85107 6.419 4.90707 6.909 5.01907C7.399 5.11707 7.882 5.29207 8.358 5.54407C8.638 5.67007 8.827 5.85207 8.925 6.09007C9.037 6.31407 9.079 6.55207 9.051 6.80407C9.023 7.04207 8.932 7.25907 8.778 7.45507C8.638 7.63707 8.456 7.76307 8.232 7.83307C8.008 7.88907 7.756 7.84707 7.476 7.70707C7.21 7.55307 6.93 7.44107 6.636 7.37107C6.356 7.30107 6.09 7.26607 5.838 7.26607C5.418 7.26607 5.047 7.33607 4.725 7.47607C4.403 7.60207 4.13 7.79107 3.906 8.04307C3.696 8.28107 3.528 8.58207 3.402 8.94607C3.29 9.31007 3.234 9.73007 3.234 10.2061C3.234 11.1301 3.458 11.8581 3.906 12.3901C4.368 12.9081 5.012 13.1671 5.838 13.1671C6.09 13.1671 6.356 13.1391 6.636 13.0831C6.916 13.0131 7.196 12.9011 7.476 12.7471C7.756 12.6071 8.008 12.5721 8.232 12.6421C8.456 12.7121 8.631 12.8451 8.757 13.0411C8.897 13.2231 8.974 13.4401 8.988 13.6921C9.016 13.9301 8.974 14.1681 8.862 14.4061C8.764 14.6301 8.582 14.8051 8.316 14.9311C7.84 15.1691 7.364 15.3371 6.888 15.4351C6.412 15.5471 5.957 15.6031 5.523 15.6031Z"
        fill="#753713"
      />
      <path
        d="M133.597 15.755C132.379 15.755 131.329 15.538 130.447 15.104C129.579 14.656 128.907 14.033 128.431 13.235C127.969 12.423 127.738 11.471 127.738 10.379C127.738 9.31502 127.962 8.38402 128.41 7.58602C128.858 6.77402 129.481 6.14402 130.279 5.69602C131.091 5.23402 132.008 5.00302 133.03 5.00302C133.772 5.00302 134.444 5.12902 135.046 5.38102C135.648 5.61902 136.166 5.96902 136.6 6.43102C137.034 6.87902 137.363 7.43202 137.587 8.09002C137.811 8.73402 137.923 9.45502 137.923 10.253C137.923 10.519 137.839 10.722 137.671 10.862C137.503 10.988 137.258 11.051 136.936 11.051H130.342V9.41302H135.634L135.298 9.70702C135.298 9.13302 135.214 8.65702 135.046 8.27902C134.878 7.88702 134.633 7.59302 134.311 7.39702C134.003 7.20102 133.618 7.10302 133.156 7.10302C132.638 7.10302 132.197 7.22202 131.833 7.46002C131.469 7.69802 131.189 8.04102 130.993 8.48902C130.797 8.93702 130.699 9.47602 130.699 10.106V10.274C130.699 11.338 130.944 12.122 131.434 12.626C131.938 13.13 132.68 13.382 133.66 13.382C133.996 13.382 134.381 13.34 134.815 13.256C135.249 13.172 135.655 13.039 136.033 12.857C136.355 12.703 136.642 12.654 136.894 12.71C137.146 12.752 137.342 12.864 137.482 13.046C137.622 13.228 137.699 13.438 137.713 13.676C137.741 13.914 137.692 14.152 137.566 14.39C137.44 14.614 137.23 14.803 136.936 14.957C136.46 15.223 135.921 15.419 135.319 15.545C134.731 15.685 134.157 15.755 133.597 15.755Z"
        fill="#753713"
      />
      <path
        d="M121.411 15.713C120.879 15.713 120.473 15.573 120.193 15.293C119.913 15.013 119.773 14.614 119.773 14.096V6.64102C119.773 6.12302 119.906 5.73102 120.172 5.46502C120.452 5.18502 120.837 5.04502 121.327 5.04502C121.831 5.04502 122.216 5.18502 122.482 5.46502C122.748 5.73102 122.881 6.12302 122.881 6.64102V7.73302H122.671C122.839 6.89302 123.21 6.24202 123.784 5.78002C124.358 5.31802 125.1 5.05902 126.01 5.00302C126.36 4.97502 126.626 5.06602 126.808 5.27602C127.004 5.47202 127.109 5.80102 127.123 6.26302C127.151 6.69702 127.06 7.04002 126.85 7.29202C126.654 7.54402 126.318 7.69802 125.842 7.75402L125.359 7.79602C124.561 7.86602 123.966 8.09702 123.574 8.48902C123.196 8.88102 123.007 9.44802 123.007 10.19V14.096C123.007 14.614 122.867 15.013 122.587 15.293C122.321 15.573 121.929 15.713 121.411 15.713Z"
        fill="#753713"
      />
      <path
        d="M111.187 15.755C110.319 15.755 109.598 15.594 109.024 15.272C108.45 14.95 108.023 14.467 107.743 13.823C107.477 13.165 107.344 12.353 107.344 11.387V6.64101C107.344 6.10901 107.477 5.71001 107.743 5.44401C108.023 5.17801 108.415 5.04501 108.919 5.04501C109.423 5.04501 109.815 5.17801 110.095 5.44401C110.375 5.71001 110.515 6.10901 110.515 6.64101V11.471C110.515 12.087 110.641 12.549 110.893 12.857C111.145 13.151 111.544 13.298 112.09 13.298C112.692 13.298 113.182 13.088 113.56 12.668C113.952 12.234 114.148 11.667 114.148 10.967V6.64101C114.148 6.10901 114.281 5.71001 114.547 5.44401C114.827 5.17801 115.219 5.04501 115.723 5.04501C116.241 5.04501 116.633 5.17801 116.899 5.44401C117.179 5.71001 117.319 6.10901 117.319 6.64101V14.096C117.319 15.174 116.808 15.713 115.786 15.713C115.282 15.713 114.897 15.573 114.631 15.293C114.365 15.013 114.232 14.614 114.232 14.096V12.689L114.547 13.508C114.239 14.222 113.798 14.775 113.224 15.167C112.65 15.559 111.971 15.755 111.187 15.755Z"
        fill="#753713"
      />
      <path
        d="M103.957 15.755C102.991 15.755 102.179 15.594 101.521 15.272C100.877 14.95 100.394 14.481 100.072 13.865C99.764 13.249 99.61 12.472 99.61 11.534V7.586H98.728C98.336 7.586 98.028 7.481 97.804 7.271C97.594 7.061 97.489 6.774 97.489 6.41C97.489 6.018 97.594 5.724 97.804 5.528C98.028 5.318 98.336 5.213 98.728 5.213H99.61V3.617C99.61 3.085 99.75 2.686 100.03 2.42C100.31 2.14 100.702 2 101.206 2C101.724 2 102.116 2.14 102.382 2.42C102.648 2.686 102.781 3.085 102.781 3.617V5.213H104.776C105.168 5.213 105.469 5.318 105.679 5.528C105.889 5.724 105.994 6.018 105.994 6.41C105.994 6.774 105.889 7.061 105.679 7.271C105.469 7.481 105.168 7.586 104.776 7.586H102.781V11.408C102.781 11.996 102.914 12.437 103.18 12.731C103.46 13.025 103.901 13.172 104.503 13.172C104.713 13.172 104.909 13.151 105.091 13.109C105.273 13.067 105.441 13.039 105.595 13.025C105.791 13.011 105.952 13.074 106.078 13.214C106.204 13.34 106.267 13.627 106.267 14.075C106.267 14.411 106.211 14.705 106.099 14.957C105.987 15.209 105.791 15.391 105.511 15.503C105.329 15.573 105.077 15.629 104.755 15.671C104.447 15.727 104.181 15.755 103.957 15.755Z"
        fill="#753713"
      />
      <path
        d="M77.977 15.755C77.221 15.755 76.542 15.608 75.94 15.314C75.338 15.02 74.869 14.621 74.533 14.117C74.197 13.613 74.029 13.046 74.029 12.416C74.029 11.66 74.225 11.065 74.617 10.631C75.009 10.183 75.646 9.86802 76.528 9.68602C77.41 9.49002 78.579 9.39202 80.035 9.39202H81.148V11.03H80.056C79.342 11.03 78.761 11.072 78.313 11.156C77.879 11.226 77.564 11.352 77.368 11.534C77.186 11.716 77.095 11.968 77.095 12.29C77.095 12.682 77.228 13.004 77.494 13.256C77.774 13.508 78.173 13.634 78.691 13.634C79.097 13.634 79.454 13.543 79.762 13.361C80.084 13.165 80.336 12.906 80.518 12.584C80.7 12.248 80.791 11.87 80.791 11.45V9.03502C80.791 8.41902 80.651 7.98502 80.371 7.73302C80.091 7.46702 79.615 7.33402 78.943 7.33402C78.565 7.33402 78.152 7.38302 77.704 7.48102C77.27 7.56502 76.787 7.71202 76.255 7.92202C75.947 8.06202 75.674 8.09702 75.436 8.02702C75.198 7.94302 75.016 7.80302 74.89 7.60702C74.764 7.39702 74.701 7.17302 74.701 6.93502C74.701 6.68302 74.771 6.44502 74.911 6.22102C75.051 5.98302 75.282 5.80802 75.604 5.69602C76.262 5.43002 76.878 5.24802 77.452 5.15002C78.04 5.05202 78.579 5.00302 79.069 5.00302C80.147 5.00302 81.029 5.16402 81.715 5.48602C82.415 5.79402 82.94 6.27702 83.29 6.93502C83.64 7.57902 83.815 8.41202 83.815 9.43402V14.096C83.815 14.614 83.689 15.013 83.437 15.293C83.185 15.573 82.821 15.713 82.345 15.713C81.869 15.713 81.498 15.573 81.232 15.293C80.98 15.013 80.854 14.614 80.854 14.096V13.319L81.001 13.445C80.917 13.921 80.735 14.334 80.455 14.684C80.189 15.02 79.846 15.286 79.426 15.482C79.006 15.664 78.523 15.755 77.977 15.755Z"
        fill="#753713"
      />
      <path
        d="M69.523 15.755C68.403 15.755 67.423 15.538 66.583 15.104C65.757 14.656 65.12 14.026 64.672 13.214C64.224 12.402 64 11.443 64 10.337C64 9.51102 64.126 8.76902 64.378 8.11102C64.63 7.45302 64.994 6.89302 65.47 6.43102C65.96 5.96902 66.548 5.61902 67.234 5.38102C67.92 5.12902 68.683 5.00302 69.523 5.00302C69.957 5.00302 70.419 5.05902 70.909 5.17102C71.399 5.26902 71.882 5.44402 72.358 5.69602C72.638 5.82202 72.827 6.00402 72.925 6.24202C73.037 6.46602 73.079 6.70402 73.051 6.95602C73.023 7.19402 72.932 7.41102 72.778 7.60702C72.638 7.78902 72.456 7.91502 72.232 7.98502C72.008 8.04102 71.756 7.99902 71.476 7.85902C71.21 7.70502 70.93 7.59302 70.636 7.52302C70.356 7.45302 70.09 7.41802 69.838 7.41802C69.418 7.41802 69.047 7.48802 68.725 7.62802C68.403 7.75402 68.13 7.94302 67.906 8.19502C67.696 8.43302 67.528 8.73402 67.402 9.09802C67.29 9.46202 67.234 9.88202 67.234 10.358C67.234 11.282 67.458 12.01 67.906 12.542C68.368 13.06 69.012 13.319 69.838 13.319C70.09 13.319 70.356 13.291 70.636 13.235C70.916 13.165 71.196 13.053 71.476 12.899C71.756 12.759 72.008 12.724 72.232 12.794C72.456 12.864 72.631 12.997 72.757 13.193C72.897 13.375 72.974 13.592 72.988 13.844C73.016 14.082 72.974 14.32 72.862 14.558C72.764 14.782 72.582 14.957 72.316 15.083C71.84 15.321 71.364 15.489 70.888 15.587C70.412 15.699 69.957 15.755 69.523 15.755Z"
        fill="#753713"
      />
      <path
        d="M87.777 19.493C87.259 19.493 86.86 19.353 86.58 19.073C86.314 18.807 86.181 18.408 86.181 17.876V6.64102C86.181 6.12302 86.314 5.73102 86.58 5.46502C86.86 5.18502 87.245 5.04502 87.735 5.04502C88.253 5.04502 88.645 5.18502 88.911 5.46502C89.177 5.73102 89.31 6.12302 89.31 6.64102V8.06902L89.079 7.25002C89.289 6.57802 89.709 6.03902 90.339 5.63302C90.983 5.21302 91.718 5.00302 92.544 5.00302C93.44 5.00302 94.224 5.22002 94.896 5.65402C95.582 6.08802 96.114 6.70402 96.492 7.50202C96.87 8.30002 97.059 9.25202 97.059 10.358C97.059 11.464 96.87 12.423 96.492 13.235C96.114 14.047 95.589 14.67 94.917 15.104C94.245 15.538 93.454 15.755 92.544 15.755C91.732 15.755 91.011 15.559 90.381 15.167C89.751 14.761 89.324 14.236 89.1 13.592H89.352V17.876C89.352 18.408 89.212 18.807 88.932 19.073C88.666 19.353 88.281 19.493 87.777 19.493ZM91.599 13.382C92.047 13.382 92.439 13.27 92.775 13.046C93.125 12.822 93.391 12.493 93.573 12.059C93.769 11.611 93.867 11.044 93.867 10.358C93.867 9.33602 93.657 8.58702 93.237 8.11102C92.817 7.62102 92.271 7.37602 91.599 7.37602C91.151 7.37602 90.752 7.48102 90.402 7.69102C90.066 7.90102 89.8 8.23002 89.604 8.67802C89.408 9.11202 89.31 9.67202 89.31 10.358C89.31 11.38 89.52 12.143 89.94 12.647C90.36 13.137 90.913 13.382 91.599 13.382Z"
        fill="#753713"
      />
      <path
        d="M179.419 15.5901C178.593 15.5901 177.858 15.3871 177.214 14.9811C176.584 14.5611 176.164 14.0081 175.954 13.3221L176.185 12.6291V13.9311C176.185 14.4491 176.052 14.8481 175.786 15.1281C175.52 15.4081 175.135 15.5481 174.631 15.5481C174.127 15.5481 173.735 15.4081 173.455 15.1281C173.189 14.8481 173.056 14.4491 173.056 13.9311V1.98208C173.056 1.45008 173.189 1.05108 173.455 0.785084C173.735 0.505084 174.134 0.365082 174.652 0.365082C175.156 0.365082 175.541 0.505084 175.807 0.785084C176.087 1.05108 176.227 1.45008 176.227 1.98208V6.95908H175.975C176.199 6.32908 176.626 5.81808 177.256 5.42608C177.886 5.03408 178.607 4.83809 179.419 4.83809C180.329 4.83809 181.12 5.05508 181.792 5.48908C182.464 5.92308 182.989 6.53908 183.367 7.33708C183.745 8.13508 183.934 9.08708 183.934 10.1931C183.934 11.2991 183.745 12.2581 183.367 13.0701C182.989 13.8821 182.457 14.5051 181.771 14.9391C181.099 15.3731 180.315 15.5901 179.419 15.5901ZM178.474 13.2171C178.922 13.2171 179.314 13.1051 179.65 12.8811C180 12.6571 180.266 12.3281 180.448 11.8941C180.644 11.4461 180.742 10.8791 180.742 10.1931C180.742 9.17108 180.532 8.42208 180.112 7.94608C179.692 7.45608 179.146 7.21108 178.474 7.21108C178.026 7.21108 177.627 7.31608 177.277 7.52608C176.941 7.73608 176.675 8.06508 176.479 8.51308C176.283 8.94708 176.185 9.50708 176.185 10.1931C176.185 11.2151 176.395 11.9781 176.815 12.4821C177.235 12.9721 177.788 13.2171 178.474 13.2171Z"
        fill="#753713"
      />
      <path
        d="M164.471 15.5901C163.603 15.5901 162.882 15.4291 162.308 15.1071C161.734 14.7851 161.307 14.3021 161.027 13.6581C160.761 13.0001 160.628 12.1881 160.628 11.2221V6.47609C160.628 5.94409 160.761 5.54509 161.027 5.27909C161.307 5.01309 161.699 4.8801 162.203 4.8801C162.707 4.8801 163.099 5.01309 163.378 5.27909C163.659 5.54509 163.799 5.94409 163.799 6.47609V11.3061C163.799 11.9221 163.925 12.3841 164.177 12.6921C164.429 12.9861 164.828 13.1331 165.374 13.1331C165.976 13.1331 166.466 12.9231 166.844 12.5031C167.236 12.0691 167.432 11.5021 167.432 10.8021V6.47609C167.432 5.94409 167.564 5.54509 167.831 5.27909C168.111 5.01309 168.503 4.8801 169.006 4.8801C169.525 4.8801 169.917 5.01309 170.183 5.27909C170.463 5.54509 170.603 5.94409 170.603 6.47609V13.9311C170.603 15.0091 170.092 15.5481 169.07 15.5481C168.566 15.5481 168.18 15.4081 167.915 15.1281C167.649 14.8481 167.516 14.4491 167.516 13.9311V12.5241L167.831 13.3431C167.523 14.0571 167.082 14.6101 166.508 15.0021C165.933 15.3941 165.255 15.5901 164.471 15.5901Z"
        fill="#753713"
      />
      <path
        d="M157.393 15.5901C156.147 15.5901 155.216 15.2471 154.6 14.5611C153.984 13.8611 153.676 12.8251 153.676 11.4531V1.98208C153.676 1.45008 153.809 1.05108 154.075 0.785084C154.355 0.505084 154.754 0.365082 155.272 0.365082C155.776 0.365082 156.161 0.505084 156.427 0.785084C156.707 1.05108 156.847 1.45008 156.847 1.98208V11.3271C156.847 11.9011 156.966 12.3281 157.204 12.6081C157.456 12.8741 157.785 13.0071 158.191 13.0071C158.303 13.0071 158.408 13.0001 158.506 12.9861C158.604 12.9721 158.709 12.9651 158.821 12.9651C159.045 12.9371 159.199 13.0071 159.283 13.1751C159.381 13.3291 159.43 13.6441 159.43 14.1201C159.43 14.5401 159.346 14.8621 159.178 15.0861C159.01 15.2961 158.751 15.4361 158.401 15.5061C158.261 15.5201 158.1 15.5341 157.918 15.5481C157.736 15.5761 157.561 15.5901 157.393 15.5901Z"
        fill="#753713"
      />
      <path
        d="M148.667 15.5901C147.547 15.5901 146.567 15.3731 145.727 14.9391C144.901 14.4911 144.264 13.8611 143.816 13.0491C143.368 12.2371 143.144 11.2781 143.144 10.1721C143.144 9.34607 143.27 8.60407 143.522 7.94607C143.774 7.28807 144.138 6.72807 144.614 6.26607C145.104 5.80407 145.692 5.45407 146.378 5.21607C147.064 4.96407 147.827 4.83807 148.667 4.83807C149.101 4.83807 149.563 4.89407 150.053 5.00607C150.543 5.10407 151.026 5.27907 151.502 5.53107C151.782 5.65707 151.971 5.83907 152.069 6.07707C152.181 6.30107 152.223 6.53907 152.195 6.79107C152.167 7.02907 152.076 7.24607 151.922 7.44207C151.782 7.62407 151.6 7.75007 151.376 7.82007C151.152 7.87607 150.9 7.83407 150.62 7.69407C150.354 7.54007 150.074 7.42807 149.78 7.35807C149.5 7.28807 149.234 7.25307 148.982 7.25307C148.562 7.25307 148.191 7.32307 147.869 7.46307C147.547 7.58907 147.274 7.77807 147.05 8.03007C146.84 8.26807 146.672 8.56907 146.546 8.93307C146.434 9.29707 146.378 9.71707 146.378 10.1931C146.378 11.1171 146.602 11.8451 147.05 12.3771C147.512 12.8951 148.156 13.1541 148.982 13.1541C149.234 13.1541 149.5 13.1261 149.78 13.0701C150.06 13.0001 150.34 12.8881 150.62 12.7341C150.9 12.5941 151.152 12.5591 151.376 12.6291C151.6 12.6991 151.775 12.8321 151.901 13.0281C152.041 13.2101 152.118 13.4271 152.132 13.6791C152.16 13.9171 152.118 14.1551 152.006 14.3931C151.908 14.6171 151.726 14.7921 151.46 14.9181C150.984 15.1561 150.508 15.3241 150.032 15.4221C149.556 15.5341 149.101 15.5901 148.667 15.5901Z"
        fill="#753713"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190.24 6.13564C188.95 5.44779 187.442 5.51265 186.384 6.29664C186.165 6.45924 186.119 6.76885 186.282 6.98817C186.444 7.2075 186.754 7.25348 186.973 7.09088C187.686 6.5627 188.778 6.47633 189.774 7.00801C190.754 7.53062 191.663 8.67137 191.904 10.6228C191.937 10.8938 192.184 11.0863 192.455 11.0528C192.726 11.0193 192.918 10.7725 192.885 10.5016C192.611 8.28211 191.546 6.83256 190.24 6.13564Z"
        fill="#E3AD45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.644 10.7856C192.678 10.1207 191.249 9.91892 190.053 10.9375C189.842 11.1169 189.816 11.433 189.996 11.6437C190.175 11.8544 190.491 11.8797 190.702 11.7004C191.48 11.0376 192.398 11.1443 193.076 11.6108C193.799 12.1089 194.036 12.8347 193.785 13.3142C193.656 13.5593 193.751 13.862 193.996 13.9904C194.241 14.1187 194.544 14.0241 194.672 13.779C195.265 12.6477 194.563 11.4189 193.644 10.7856Z"
        fill="#E3AD45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M196.416 14.2275C196.345 13.9607 196.071 13.8022 195.804 13.8734C195.562 13.938 195.457 13.9541 195.343 13.9712C195.341 13.9716 195.338 13.972 195.335 13.9724C195.201 13.9925 195.054 14.0146 194.763 14.0874C194.495 14.1544 194.332 14.4259 194.399 14.6937C194.466 14.9616 194.737 15.1245 195.005 15.0576C195.251 14.9962 195.365 14.9792 195.479 14.962C195.483 14.9614 195.487 14.9607 195.492 14.9601C195.633 14.9389 195.776 14.9159 196.062 14.8395C196.329 14.7683 196.487 14.4943 196.416 14.2275Z"
        fill="#E3AD45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M194.633 15.0681C194.373 14.9729 194.086 15.1058 193.991 15.365C193.946 15.4868 193.895 15.7099 193.859 15.8715C193.821 16.047 193.788 16.2092 193.781 16.2451L193.781 16.2464C193.727 16.5172 193.902 16.7806 194.173 16.8348C194.444 16.8889 194.707 16.7133 194.761 16.4425L194.762 16.4414C194.768 16.4071 194.799 16.2523 194.836 16.0862C194.876 15.9021 194.913 15.7541 194.929 15.7099C195.025 15.4507 194.892 15.1634 194.633 15.0681Z"
        fill="#E3AD45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.94 6.49371C186.81 6.2499 186.507 6.15738 186.263 6.28705C185.735 6.56818 185.401 6.98337 185.304 7.50906C185.213 8.00497 185.347 8.52055 185.569 8.99878C186.013 9.95352 186.918 10.993 187.872 11.9218C188.837 12.8617 189.898 13.7319 190.703 14.3479C191.105 14.6561 191.448 14.9035 191.686 15.0664C191.802 15.1464 191.902 15.2115 191.974 15.2536L191.974 15.2539C191.999 15.2684 192.058 15.3029 192.121 15.325C192.138 15.331 192.182 15.3455 192.238 15.3518C192.239 15.3518 192.239 15.3519 192.24 15.352C192.268 15.3552 192.47 15.3788 192.64 15.2179C192.861 15.0094 192.79 14.7474 192.772 14.6949C192.748 14.6213 192.712 14.5706 192.701 14.5551C192.635 14.4623 192.543 14.3989 192.442 14.3678C192.398 14.3403 192.334 14.2984 192.25 14.2411C192.03 14.0905 191.703 13.8539 191.31 13.5538C190.526 12.9532 189.498 12.1092 188.57 11.2054C187.63 10.2906 186.839 9.35703 186.476 8.57736C186.295 8.18839 186.25 7.89643 186.288 7.69047C186.32 7.5143 186.426 7.33331 186.733 7.16994C186.977 7.04027 187.069 6.73751 186.94 6.49371ZM192.495 14.3997C192.495 14.3996 192.494 14.3991 192.492 14.3981C192.494 14.3992 192.495 14.3997 192.495 14.3997ZM191.878 15.123C191.882 15.1288 191.885 15.1331 191.887 15.1356C191.884 15.1314 191.881 15.1272 191.878 15.123Z"
        fill="#E3AD45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.803 9.71262C183.679 9.95953 183.779 10.2599 184.026 10.3836C184.455 10.5982 184.949 10.6886 185.412 10.7245C185.88 10.7606 186.352 10.7435 186.751 10.7206C187.026 10.7049 187.237 10.4686 187.221 10.1929C187.206 9.91722 186.969 9.7065 186.694 9.72227C186.306 9.74445 185.888 9.75828 185.489 9.72746C185.087 9.69631 184.738 9.62175 184.474 9.4894C184.227 9.36576 183.927 9.4657 183.803 9.71262Z"
        fill="#E3AD45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191.406 14.2251C191.164 14.0923 190.86 14.1809 190.728 14.4231C190.728 14.422 190.728 14.4225 190.727 14.4246C190.722 14.4308 190.709 14.4514 190.68 14.4915C190.645 14.5389 190.6 14.5989 190.545 14.6697C190.463 14.776 190.365 14.8991 190.262 15.03C190.227 15.0738 190.192 15.1185 190.156 15.1638C190.015 15.3417 189.87 15.5268 189.75 15.6872C189.64 15.8342 189.522 15.9991 189.461 16.1209C189.337 16.3679 189.437 16.6682 189.684 16.7917C189.931 16.9152 190.232 16.8151 190.355 16.5681C190.355 16.5686 190.356 16.5667 190.359 16.5618C190.361 16.557 190.365 16.5505 190.37 16.5422C190.381 16.5253 190.395 16.5039 190.413 16.4777C190.449 16.425 190.496 16.3605 190.551 16.2861C190.662 16.1373 190.8 15.9614 190.941 15.7835C190.975 15.7403 191.009 15.697 191.043 15.654C191.148 15.5219 191.25 15.3928 191.336 15.2819C191.437 15.1506 191.551 15.0009 191.604 14.9039C191.737 14.6618 191.649 14.3579 191.406 14.2251Z"
        fill="#E3AD45"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.002 7.7852C182.98 8.06053 183.186 8.30087 183.462 8.32201C183.604 8.33293 183.722 8.34087 183.825 8.34782C183.95 8.35619 184.053 8.36315 184.152 8.37218C184.322 8.38764 184.425 8.40539 184.512 8.43243C184.682 8.48534 184.857 8.59246 185.335 8.97781C185.55 9.15106 185.865 9.1172 186.038 8.90217C186.212 8.68714 186.178 8.37238 185.963 8.19912C185.499 7.82547 185.186 7.5951 184.81 7.47776C184.623 7.41969 184.439 7.39413 184.243 7.3763C184.138 7.36676 184.013 7.35821 183.873 7.34875C183.768 7.34161 183.656 7.33395 183.538 7.32495C183.263 7.3038 183.023 7.50987 183.002 7.7852Z"
        fill="#E3AD45"
      />
    </svg>
  );
};
